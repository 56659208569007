.react-datepicker__input-container input {
    border: 0 !important;
    padding: 0 !important;
    box-shadow: 0 !important;
    width: 100% !important;
}

.check-class {
    color: green;
}

.x-class {
    color: red;
}

.video-container {
    // width:100vw;
    // height:100vh;
    overflow:hidden;
    position:relative;
  }
  
  
  .video-container iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
  
  .video-container iframe {
    pointer-events: none;
  }

  .video-container iframe {
    position: absolute;
    top: -60px;
    left: 0;
    // width: 100%;
    // height: calc(100% + 120px);
  }

  .video-foreground {
    pointer-events:none;
  }

  .remove-bullet{
      list-style: none !important;
      margin: 0 !important;
      padding: 0 !important;
  }